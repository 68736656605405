import React from 'react';
import {Link} from 'react-router-dom';
import 'intro.js/introjs.css';
import introJs from 'intro.js';
import Dashboard from '../modules/Dashboard';
import handleResetClick from '../modules/Dashboard';



const SideBar = () => {

console.log(handleResetClick);
    const startIntroTour = () => {
        introJs()
          .setOptions({
            steps: [
              {
                element: document.querySelector('.profile_marker'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce champ vous <b>permet de selectionner le profil (poste) pour lequel vous désirez obtenir des résultats<b/>',
              },

              {
                element: document.querySelector('.date_debut_marker'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce champ vous <b>permet de selectionner une date de début afin d\'ouvrir un intervalle de recherche<b/>',
              },
              {
                element: document.querySelector('.date_fin_marker'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce champ vous <b>permet de selectionner une date de fin afin de fermer l\'un intervalle de recherche ouvert avec le champs Date de debut<b/>',
              },
              {
                element: document.querySelector('.filter'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce bouton vous <b>permet d\'afficher plus d\'options pour filtrer les resultats de votre recherche<b/>',
              },
              {
                element: document.querySelector('.reset'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce bouton vous <b>permet de rafraîchir la page <b/>',
              },


              /*
              {
                element: document.querySelector('.search-area'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ceci est la zone de recherche où vous trouverez tous les champs permettant de filtrer les résultats.',
              },
              {
                element: document.querySelector('.search_marker'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce bouton vous <b>permet de lancer votre recherche<b/>',
              },



              {
                element: document.querySelector('.keyword_marker'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce champ vous permet de chercher selon un mot clé. Vous pouvez <b>saisir un NOM, un PRENOM, un POSTE.</b> Exemple: INFORMATICIEN',
              },
              {
                element: document.querySelector('.order_by_marker'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce champ vous <b>permet de classer les résultats</b> selon les colonnes NOM, PRENOMS, POSTE, DATE ',
              },
              {
                element: document.querySelector('.direction_marker'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce champ vous <b>permet de préciser si vous voulez que les résultats s\'affichent du plus ancien au plus récent (ASC) ou du plus récent au plus ancien (DESC)<b/>',
              },
              {
                element: document.querySelector('.page_marker'), // Remplacez par l'élément réel que vous souhaitez mettre en surbrillance
                intro: 'Ce champ vous <b>permet de definir combien de résultat devront s\'afficher par page<b/>',
              },
              
              */
              // Ajoutez plus d'étapes au besoin
            ],
          })
          .start();
      };

    return (
        <div id="layoutSidenav_nav">
        <nav class="sb-sidenav accordion bg-theme-basic" id="sidenavAccordion">
            <div class="sb-sidenav-menu">
                <div class="nav">
                    <div class="sb-sidenav-menu-heading" style={{color:'white',marginBottom:-20}}><i class="fa-brands fa-cpanel" style={{fontSize:60}}></i></div>
 {
  /*
                     <Link class="nav-link" onClick={startIntroTour} style={{marginBottom:-10}}>
                        <div class="sb-nav-link-icon"><i className="fa-solid fa-binoculars"></i></div>
                         Guide utilisateur
                    </Link>

   */
 }
{/* Utilisez la fonction handleResetClick dans votre composant */}
                        <Link className="nav-link">
                            <div className="sb-nav-link-icon">
                                <i className="fa fa-people-line" style={{ fontSize: 24 }}></i>
                            </div>
                            Candidats
                        </Link>




                </div>
            </div>
            <div class="sb-sidenav-footer bg-theme text-silver">
                <div class="small">connecté en tant que:</div>
                {localStorage.name != undefined ? localStorage.name : null}
            </div>
        </nav>
    </div>
    );
};

export default SideBar;