import axios from 'axios';
import React, { useState } from 'react';
import Constants from '../../../Constants';
import logo from '../../../assets/img/logo.png';




const Login = () => {
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleInput = (e) => {
    setInput((prevInput) => ({ ...prevInput, [e.target.name]: e.target.value }));
    /*
      prevInput représente la valeur actuelle de l'état input au moment où la mise à jour de l'état est déclenchée.    
      ...prevInput est utilisée pour créer une copie de cet objet afin de ne pas modifier directement l'objet input existant. 
       Ensuite, vous ajoutez ou mettez à jour une propriété dans cet objet en fonction de la saisie de l'utilisateur.
       cette copie sera affectée à l'objet input
     */
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    // Effectuer la requête de connexion
    axios
      .post(`${Constants.BASE_URL}/login`, input)
      .then((res) => {
        // En cas de succès, mettre à jour les données locales et recharger la page
        localStorage.email = res.data.email;
        localStorage.name = res.data.name;
        localStorage.photo = res.data.photo;
        localStorage.phone = res.data.phone;
        localStorage.token = res.data.token;
        setIsLoading(true);
        
        window.location.reload();
      })
      .catch((error) => {
        // En cas d'erreur, gérer les erreurs
        setIsLoading(false);
        if (error.response && error.response.status === 422 && error.response.data.errors) {
          setErrors(error.response.data.errors);
          console.log(error.response.data.errors);
        }
        
      });
  };

  return (
    <div className='container-fluid bg-image' id={'login'}>
      <div className='row'>
        <div className='col-12 col-md-6 offset-md-3'>        
          <div className='card custom-card'>
          <center><img src={logo} className='responsive mt-4' style={{width:300}}/></center>
            <br/>
            <center><h4 className='para'>CVTHEQUE ALUCAM</h4></center>
            <div className='card-body'>
              <label className='w-100'>
                <p className='para'><i className='fa fa-user'></i> Identifiant</p>
                <input
                  className={errors.email !== undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                  type='text'
                  name='email'
                  value={input.email || ''}
                  onChange={handleInput}
                  autoComplete='off'  // Ajout de l'attribut autocomplete avec la valeur 'off'
                />
                <p className='login-error-msg'><small>{errors.email !== undefined ? errors.email[0] : null}</small></p>
              </label>
              <label className='w-100 mt-4'>
              <p className='para'><i className='fa fa-key'></i> Mot de Passe</p>
              <div className='input-group'>
                <input
                  className={errors.password !== undefined ? 'form-control is-invalid' : 'form-control'}
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  value={input.password || ''}
                  onChange={handleInput}
                  autoComplete='off'
                />
                <button className='btn btn-outline-secondary' type='button' onClick={toggleShowPassword} style={{ background: '#ffffff', color: '#000000' }}>
                  <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                </button>
              </div>
              <p className='login-error-msg'><small>{errors.password !== undefined ? errors.password[0] : null}</small></p>
            </label>
              <div className=' mt-4 d-flex justify-content-end'>
                <button className='login_btn btn btn-outline-warning' onClick={handleLogin} dangerouslySetInnerHTML={{ __html: isLoading ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Login ...' : 'Connexion' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
