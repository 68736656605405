import React, { useEffect, useState } from 'react';
import BreadCrumb from '../partials/BreadCrumb';
import CardHeader from '../partials/miniComponent/CardHeader/CardHeader';
import axios from 'axios';
import Constants from '../../Constants';
import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from '../partials/miniComponent/Loader';
import NoDataFound from '../partials/miniComponent/NoDataFound';



const Dashboard = () => {
    const [foundCount, setFoundCount] = useState(0);
    const [totalCount, setTotalCount] = useState('');
    const [totalRecords, setTotalRecords] = useState('');
        // Obtenez la date actuelle au format "YYYY-MM-DD"
    const currentDate = new Date().toISOString().split('T')[0];
    // Obtenez la date et l'heure actuelles au format "YYYY-MM-DDTHH:mm"
    const currentDateTime = new Date().toISOString().slice(0, 16);

    const [input2, setInput2] = useState({
        order_by: 'created_at',
        per_page: 10,
        direction: 'desc',
        search: '',
        startDate: '',  // Nouvelle entrée pour la date de début
        endDate: '',    // Nouvelle entrée pour la date de fin
        annee_experience: '', 
        last_degree: '', 
    });
    const [showAdditionalCriteria, setShowAdditionalCriteria] = useState(false);
    const [showResults, setShowResults] = useState(false); // État pour gérer la visibilité du tableau    
    const [isLoading, setIsLoading] = useState(false);
    const [itemsCountPerPageData, setItemsCounterPerPageData] = useState(0);
    const [totalItemsCountData, setTotalItemsCountData] = useState(1);
    const [startFromData, setStartFromData] = useState('');
    const [activePageData, setActivePageData] = useState(1);;
    const [datas, setDatas] = useState([]);
    const [profils, setProfils] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState('');
    const [resetCounter, setResetCounter] = useState(0);

    const handleResetClick = () => {
        setShowResults(false);
        // Réinitialiser le formulaire en remettant les valeurs par défaut
        setInput2({
            order_by: 'created_at',
            per_page: 10,
            direction: 'desc',
            search: '',
            startDate: '',
            endDate: '',
            annee_experience: '', 
            last_degree: '',             
        });
        // Incrémentez le compteur pour déclencher un nouveau rendu du composant
        setResetCounter((prevCounter) => prevCounter + 1);
    };

    const handleResetAllPageClick = () => {    
        // Rafraîchir la page
        window.location.reload();
    };
    

    const handleShowAdditionalCriteria = () => {
        setShowAdditionalCriteria(!showAdditionalCriteria);
    };
      
    const handleProfileChange = (e) => {
      setSelectedProfile(e.target.value);
    };
    
    const handleInput = (e) => {
        setInput2(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
    
    const handleSearchClick = () => {
        setShowResults(true); // Mettre à jour l'état lorsque le bouton de recherche est cliqué
        getDatas(1); // Appeler votre fonction getDatas ici avec les paramètres nécessaires
    };
 /*
    const getDatas = (pageNumber) =>{
        setIsLoadingData(true);
        axios.get(`${Constants.BASE_URL}/data?page=${pageNumber}&search=${input2.search}&order_by=${input2.order_by}&per_page=${input2.per_page}&direction=${input2.direction}`).then(res => {        
            setDatas(res.data.data);
            setItemsCounterPerPageData(res.data.meta.per_page);
            setStartFromData(res.data.meta.from);
            setTotalItemsCountData(res.data.meta.total);
            setActivePageData(res.data.meta.current_page);
            setIsLoadingData(false);
            console.log(res.data);
        })
    }*/

    /*
    const getDatas = (pageNumber) => {
        setIsLoading(true);      
        axios.get(`${Constants.BASE_URL}/data?page=${pageNumber}&search=${input2.search}&order_by=${input2.order_by}&per_page=${input2.per_page}&direction=${input2.direction}&profile=${selectedProfile}`)
          .then(res => {
            setDatas(res.data.data);
            setItemsCounterPerPageData(res.data.meta.per_page);
            setStartFromData(res.data.meta.from);
            setTotalItemsCountData(res.data.meta.total);
            setActivePageData(res.data.meta.current_page);
            setIsLoading(false);
            console.log(res.data);
          })
          .catch(error => {
            setIsLoading(false);
            console.error(error);
          });
      };
*/         

      const getDatas = (pageNumber) => {
        setIsLoading(true);
        axios.get(`${Constants.BASE_URL}/data?page=${pageNumber}&search=${input2.search}&order_by=${input2.order_by}&per_page=${input2.per_page}&direction=${input2.direction}&profile=${selectedProfile}&annee_experience=${input2.annee_experience}&last_degree=${input2.last_degree}&start_date=${input2.startDate}&end_date=${input2.endDate}`)
            .then(res => {
                setDatas(res.data.data);
                setItemsCounterPerPageData(res.data.meta.per_page);
                setStartFromData(res.data.meta.from);
                setTotalItemsCountData(res.data.meta.total);
                setActivePageData(res.data.meta.current_page);
                setFoundCount(res.data.data.length); // Mettez à jour le nombre d'effectifs trouvés
                setTotalCount(res.data.meta.total); // Mettez à jour l'effectif total    
                setIsLoading(false);
                console.log(res.data);
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);
            });
    };
    

    useEffect(() => {
      getDatas();
    }, [])


    useEffect(() => {
        // Obtenir la liste des profils
        axios.get(`${Constants.BASE_URL}/postes`)
          .then(res => {
            setProfils(res.data.postes);
            console.log(res.data.postes);
          })
          .catch(error => {
            console.error(error);
          });        
        // Appeler la fonction getDatas
        getDatas(1);
      }, []);
    
      useEffect(() => {
        axios.get(`${Constants.BASE_URL}/count`)
        .then(res => {
            setTotalRecords(res.data.total_count);
            //console.log('totalRecords');
            //console.log(res.data.total_count);
            //console.log(totalRecords);
        })
        .catch(error => {
            console.error(error);
        });

      }, [])



    return (
        <>
            
                <div className='row' style={{marginTop:70}}>
                    <div className='col-md-12'>
                        <div className='card mb-4'>
                            <div className=''>
                                <CardHeader
                                        title={`Total des candidatures: ${totalRecords}`} 
                                />              
                                                      
                            </div>  
                            <div className='card-body'>
                                

                                <div className='search-area mb-4'>


                                    <div className='row'>
                                        <div className='col-md-2'>
                                        <label className={'w-100'}>
                                            <p style={{fontWeight:'bolder'}}>Profils</p>
                                            <select
                                            className='form-select form-select-sm profile_marker'
                                            name={'selectedProfile'}
                                            value={selectedProfile}
                                            onChange={handleProfileChange}
                                            >
                                            <option value=''>Afficher tous les profils</option>
                                            {profils.map((profil, index) => (
                                                <option key={index} value={profil}>{profil}</option>
                                            ))}
                                            </select>
                                        </label>
                                        </div>
                                        {/* ... Autre code JSX ... */}
 

                                        <div className='col-md-2'>
                                                <label className={'w-100'}>
                                                    <p style={{fontWeight:'bolder'}}>Dernier diplôme</p>
                                                    <select
                                                        className='form-select form-select-sm order_by_marker'
                                                        name={'last_degree'}
                                                        value={input2.last_degree}
                                                        onChange={handleInput}
                                                    >
                                                        <option value={''}>Selectionnez un diplôme</option>
                                                        <option value={'BAC'}>BAC</option>
                                                        <option value={'LICENCE'}>LICENCE</option>
                                                        <option value={'MASTER'}>MASTER</option>
                                                        <option value={'DOCTORAT'}>DOCTORAT</option>
                                                    </select>
                                                </label>
                                        </div>
                                        <div className='col-md-2'>
                                                <label className={'w-100'}>
                                                    <p style={{fontWeight:'bolder'}}>Expérience</p>
                                                    <select
                                                        className='form-select form-select-sm order_by_marker'
                                                        name={'annee_experience'}
                                                        value={input2.annee_experience}
                                                        onChange={handleInput}
                                                    >
                                                        <option value={''}>Selectionnez une valeur</option>
                                                        <option value={'1'}>1 ans</option>
                                                        <option value={'2'}>2 ans</option>
                                                        <option value={'3'}>3 ans</option>
                                                        <option value={'4'}>4 ans</option>
                                                        <option value={'5'}>5 ans</option>
                                                        <option value={'6'}>6 ans</option>
                                                        <option value={'7'}>7 ans</option>
                                                        <option value={'8'}>8 ans</option>
                                                        <option value={'9'}>9 ans</option>
                                                        <option value={'10'}>10 ans</option>
                                                        <option value={'11'}>11 ans</option>
                                                        <option value={'12'}>12 ans</option>
                                                        <option value={'13'}>13 ans</option>
                                                        <option value={'14'}>14 ans</option>
                                                        <option value={'15'}>15 ans</option>
                                                        <option value={'16'}>16 ans</option>
                                                        <option value={'17'}>17 ans</option>
                                                        <option value={'18'}>18 ans</option>
                                                        <option value={'19'}>19 ans</option>
                                                        <option value={'20'}>20 ans</option>
                                                    </select>
                                                </label>
                                        </div>

                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                                <p style={{fontWeight:'bolder'}}>Date de début</p>
                                                <input
                                                    className='form-control form-control-sm date_debut_marker'
                                                    name={'startDate'}
                                                    //type={'datetime-local'}
                                                    type={'date'}
                                                    value={input2.startDate}
                                                    onChange={handleInput}
                                                    max={currentDate} // La date maximale est la date actuelle
                                                />
                                            </label>
                                        </div>
                                        <div className='col-md-2'>
                                            <label className={'w-100'}>
                                                <p style={{fontWeight:'bolder'}}>Date de fin</p>
                                                <input
                                                    className='form-control form-control-sm date_fin_marker'
                                                    name={'endDate'}
                                                    //type={'datetime-local'}
                                                    type={'date'}
                                                    value={input2.endDate}
                                                    onChange={handleInput}
                                                    max={currentDate} // La date maximale est la date actuelle                                                    
                                                />
                                            </label>
                                        </div>

                                         {/* Nouveau bloc de critères, conditionnellement affiché */}
                            {showAdditionalCriteria && (
                                        <>
                                            <div className='col-md-2'>
                                                <label className={'w-100'}>
                                                    <p style={{fontWeight:'bolder'}}>chercher</p>
                                                    <input
                                                        className='form-control form-control-sm keyword_marker'
                                                        name={'search'}
                                                        type={'search'}
                                                        value={input2.search}
                                                        onChange={handleInput}
                                                        placeholder={'mot clé...'}
                                                    />
                                                </label>
                                            </div>                                            
                                            <div className='col-md-3'>
                                                <label className={'w-100'}>
                                                    <p style={{fontWeight:'bolder'}}>classer par</p>
                                                    <select
                                                        className='form-select form-select-sm order_by_marker'
                                                        name={'order_by'}
                                                        value={input2.order_by}
                                                        onChange={handleInput}
                                                    >
                                                        <option value={'nom'}>Nom</option>
                                                        <option value={'prenoms'}>Prenoms</option>
                                                        <option value={'poste'}>Poste</option>
                                                        <option value={'created_at'}>Date</option>
                                                    </select>
                                                </label>
                                            </div>

                                            <div className='col-md-2'>
                                                <label className={'w-100'}>
                                                    <p style={{fontWeight:'bolder'}}>direction</p>
                                                    <select
                                                        className='form-select form-select-sm direction_marker'
                                                        name={'direction'}
                                                        value={input2.direction}
                                                        onChange={handleInput}
                                                    >
                                                        <option value={'asc'}>ASC</option>
                                                        <option value={'desc'}>DESC</option>
                                                    </select>
                                                </label>
                                            </div>
                                            <div className='col-md-2'>
                                                <label className={'w-100'}>
                                                    <p style={{fontWeight:'bolder'}}>Nombre de CV par page</p>
                                                    <select
                                                        className='form-select form-select-sm page_marker'
                                                        name={'per_page'}
                                                        value={input2.per_page}
                                                        onChange={handleInput}
                                                    >
                                                        <option value={10}>10</option>
                                                        <option value={25}>25</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </select>
                                                </label>
                                            </div>
                                                                    {/* ... Autre code JSX ... */}



                                                        {/* Ajoutez d'autres champs de critères au besoin */}
                                         </>
                                                )}                    
                                            <div className='col-md-2'>
                                            <div className='d-grid mt-4'>
                                                <button className={'btn btn-sm theme-button login_btn search_marker'}  onClick={handleSearchClick}
                                                    style={{marginTop:-4,borderRadius:10}}
                                                >
                                                    <i className='fa-solid fa-magnifying-glass'/>
                                                    &nbsp;&nbsp;Chercher
                                                </button>
                                            </div>
                                            </div>                                    
                                    </div>
                                    <div className='row'>

                                            {/* Nouveau bloc avec le bouton "plus de critère" */}
                                            <div className='col-1' style={{width:'58px'}}>
                                                <label className={'w-100'}>
                                                    {/**<p style={{fontWeight:'bolder'}}>Plus de critères</p> */}
                                                        <button
                                                        className={'btn btn-sm login_btn_def'}
                                                        name={'filter'}
                                                        onClick={handleShowAdditionalCriteria}
                                                        style={{marginTop:18}}
                                                    >
                                                        {showAdditionalCriteria ? (
                                                            <>
                                                                <i className='fas fa-filter'></i>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className='fas fa-filter'></i>
                                                            </>
                                                        )}
                                                    </button>
                                                </label>

                                            </div>
                                            <div className='col-1' style={{width:'58px'}}>
                                                <label className={''}>
                                                {/**<p style={{fontWeight:'bolder'}}>Rafraîchir</p> */}
                                                    <button
                                                        className="btn btn-sm login_btn_def"
                                                        onClick={handleResetAllPageClick}
                                                        name={'reset'}
                                                        style={{marginTop:18}}
                                                    >
                                                        <i class="fa-solid fa-arrows-rotate"></i>
                                                    </button>                                        
                                                </label>

                                            </div>
                                    </div>                                    
                                </div>

                                {/**
                                <p style={{fontWeight:'bolder'}}>Total des Canditatures: {totalRecords}</p>
                                 **/}

                                 {showResults && !isLoading && totalCount && (
  <>

    <p style={{ marginBottom: 20, marginTop: 15, fontSize: 15, color: '#12b612', fontWeight: '700' }}>{totalCount} {totalCount > 1 ? 'résultats correspondent' : 'résultat correspond'} à vos critères de recherche.</p>
  </>
)}

                                

{showResults && (
                <>
                {isLoading ? (
            <Loader />
        ) : (
            <div className='table-responsive soft-landing'>
                                            <table className={'my-table position-relative table table-hover table-striped table-bordered'}>
                                                <thead>
                                                    <tr>
                                                        {/* <th>ID</th> */}
                                                        <th>Nom</th>
                                                        <th>Prénoms</th>
                                                        <th>Dernier diplôme</th>                                                        
                                                        <th>Expériences</th>                                                        
                                                        <th>CV</th>
                                                        <th>Poste</th>
                                                        <th>Date</th>
                                                        <th>Email</th>
                                                        <th>Téléphone</th>                                                        
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {Object.keys(datas).length > 0 ? datas.map((candidate, index) =>(
                                                        <tr key={index}>
                                                        
                                                        {/* <td>{index+1}</td> */}
                                                        <td width={'10%'}>
                                                            <p className={'text-theme'}>{candidate.nom}</p>
                                                        </td>
                                                        <td width={'10%'}>
                                                            <p className={'text-theme'}>{candidate.prenoms}</p>
                                                        </td>
                                                        <td width={'10%'}>
                                                            <p className={'text-theme'}>{candidate.dernier_diplome}</p>
                                                        </td>                                               
                                                        <td width={'10%'}>{candidate.annee_experience ? candidate.annee_experience + ' ans' : ''}</td>                                                                                                            
                                                        <td width={'10%'}>
                                                            <Link to={candidate.cv} target="_blank">                                                            
                                                                <center>
                                                                    <i class="fa-solid fa-file-pdf" style={{fontSize:35, color:'#014272'}}/>
                                                                </center>
                                                            </Link>
                                                        </td>
                                                        <td width={'10%'}>{candidate.poste}</td>                                                             
                                                        <td width={'10%'}>
                                                            <p className={'text-theme'}><small> {candidate.created_at}</small></p>
                                                        </td>
                                                        <td width={'10%'}>
                                                            {candidate.email}
                                                        </td>
                                                        <td width={'10%'}>
                                                            {candidate.telephone}
                                                        </td>
                                                    </tr>                                            
                                                    
                                                )): <NoDataFound/>}
                                                </tbody>
                                                
                                            </table>
                    </div>
        )}
{/* Conditionnez le rendu du tableau en fonction de l'état showResults */}
{Object.keys(datas).length > 0 && (
            <div className='card-footer'>
                <nav className={'pagination-sm'}>
                    <Pagination
                        activePage={activePageData}
                        itemsCountPerPage={itemsCountPerPageData}
                        totalItemsCount={totalItemsCountData}
                        pageRangeDisplayed={5}
                        onChange={getDatas}
                        nextPageText={'suivant'}
                        firstPageText={'Premier'}
                        prevPageText={'Précédent'}
                        lastPageText={'Dernier'}
                        itemClass={'page-item'}
                        linkClass={'page-link'}                        
                    />
                </nav>
            </div>
        )}        
                </>
            )}




                            </div>   
                        </div>


                    </div>
                </div>        

        </>
    );
};

export default Dashboard;
