// ProjectRouter.js
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Master from '../layouts/Master';
import Dashboard from '../modules/Dashboard';
import Error500 from '../modules/Error500';
import Test from '../modules/Test';
import Profile from '../modules/profil/Profile';

const ProjectRouter = createBrowserRouter([
  {
    path: '/',
    element: <Master />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/profil',
        element: <Profile/>,
      },
      {
        path: '/error-500',
        element: <Error500 />,
      },
      {
        path: '/test',
        element: <Test />,
      },
      
    ],
  },
]);

export default ProjectRouter;