import React from 'react';
import { Link } from 'react-router-dom';

const CardHeader = (props) => {

    const handleRefresh = () => {
        window.location.reload();
    };


    return (
        <>
            <div className='card-header'>
                <div className='d-flex justify-content-between align-items-center'>
                    <h4 className={'text-theme'}> <i style={{fontSize:30}} className='fa fa-people-group'></i> {props.title} &nbsp;
{/*
                    <button
                                className="btn btn-sm login_btn_def"
                                onClick={handleRefresh}
                                >
                              <i class="fa-solid fa-arrows-rotate"></i>
                    </button>
 */}
                    </h4>
                </div>
            </div>
        </>
    );
};

export default CardHeader;