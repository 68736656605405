import React from 'react';

const Footer = () => {
    return (
        <footer class="py-1 bg-theme mt-auto">
        <div class="container-fluid px-4">
            <div class="d-flex align-items-center justify-content-between small">
                <small class="text-silver">Copyright &copy; CVTHEQUE ALUCAM {new Date().getFullYear()} | Version: 0.1.0 Beta</small>
                <div>
                    <small class="text-silver">Conçu et developpé par <a href='#' className={'text-white'}>CYNOMEDIA AFRICA</a></small>
                </div>
            </div>
        </div>
    </footer>
    );
};

export default Footer;